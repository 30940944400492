import React, { useState, useEffect } from 'react';
import gsap from 'gsap';
import './MenuStickyNote.css';
import Tape from '../assets/Tape.webp';
import NotebookBackground from '../assets/Notebook.jpg';
import Paperclip from '../assets/Paperclip.png';

const MenuStickyNote = ({ activeSection }) => {
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    gsap.fromTo(
      '.menu-notepaper-container',
      { y: '-200px', scale: 1.5, opacity: 0 },
      { y: '0px', scale: 1, opacity: 1, duration: 0.5, ease: 'power2.out' }
    );

    gsap.fromTo(
      '.menu-shadow',
      { scale: 2, opacity: 0.4 },
      { scale: 1, opacity: 0.2, duration: 0.5, ease: 'power2.out' }
    );
  }, []);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
    const dimmedOverlay = document.querySelector('.dimmed-overlay');

    if (!menuOpen) {
      gsap.to('.menu-pullout', { x: '0%', duration: 0.5, ease: 'power2.out' });
      if (dimmedOverlay) {
        dimmedOverlay.classList.add('active');
      }
    } else {
      gsap.to('.menu-pullout', { x: '-100%', duration: 0.5, ease: 'power2.in' });
      if (dimmedOverlay) {
        dimmedOverlay.classList.remove('active');
      }
    }
  };

  const handleMenuClick = (event, targetId) => {
    event.preventDefault();
    const targetSection = document.querySelector(targetId);
    if (targetSection) {
      targetSection.scrollIntoView({ behavior: 'smooth' });

      // Manually update the hash in the URL
      setTimeout(() => {
        window.history.pushState(null, '', targetId); // Update the hash
      }, 500);
    }
    toggleMenu();
  };

  return (
    <>
      <div className="menu-notepaper-container">
        <div className="menu-shadow"></div>
        <img src={Tape} alt="Tape" className="menu-tape" />
        <div className="menu-notepaper" onClick={toggleMenu}>
          Menu
        </div>
      </div>

      <div
        className="menu-pullout"
        style={{
          backgroundImage: `url(${NotebookBackground})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        <button className="menu-close-btn" onClick={toggleMenu}>
          X
        </button>
        <ul>
          <li>
            <a href="#homepage" className={activeSection === '#homepage' ? 'active' : ''} onClick={(e) => handleMenuClick(e, '#homepage')}>
              Homepage {activeSection === '#homepage' && <img src={Paperclip} alt="Paperclip" className="paperclip" />}
            </a>
          </li>
          <li>
            <a href="#about" className={activeSection === '#about' ? 'active' : ''} onClick={(e) => handleMenuClick(e, '#about')}>
              About {activeSection === '#about' && <img src={Paperclip} alt="Paperclip" className="paperclip" />}
            </a>
          </li>
          <li>
            <a href="#skills" className={activeSection === '#skills' ? 'active' : ''} onClick={(e) => handleMenuClick(e, '#skills')}>
              Skills {activeSection === '#skills' && <img src={Paperclip} alt="Paperclip" className="paperclip" />}
            </a>
          </li>
          <li>
            <a href="#works" className={activeSection === '#works' ? 'active' : ''} onClick={(e) => handleMenuClick(e, '#works')}>
              Works {activeSection === '#works' && <img src={Paperclip} alt="Paperclip" className="paperclip" />}
            </a>
          </li>
          <li>
            <a href="#contact" className={activeSection === '#contact' ? 'active' : ''} onClick={(e) => handleMenuClick(e, '#contact')}>
              Contact {activeSection === '#contact' && <img src={Paperclip} alt="Paperclip" className="paperclip" />}
            </a>
          </li>
        </ul>
      </div>

      <div className="dimmed-overlay"></div>
    </>
  );
};

export default MenuStickyNote;
