import React from 'react';
import './ProjectPolaroid.css';

function ProjectPolaroid({ imageSrc, title, description, skills, projectLink }) {
  return (
    <div className="project-container">
      {/* Add the anchor tag around the polaroid */}
      <a href={projectLink} target="_blank" rel="noopener noreferrer" className="project-link">
        <div className="project-polaroid">
          <img src={imageSrc} alt={title} className="project-polaroid-image" />
          <div className="project-polaroid-title">{title}</div>
        </div>
      </a>
      <div className="project-details">
        <p className="project-description">{description}</p>
        <div className="project-sticky-notes">
          {skills.map((skill, index) => (
            <div key={index} className="project-sticky-note" style={{ backgroundColor: skill.color }}>
              {skill.name}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default ProjectPolaroid;
