import ProjectPolaroid from '../../components/ProjectPolaroid';
import './Works.css';
import CSA from '../../assets/CSA.png';
import Pickle from '../../assets/Pickle.png';
import Smart from '../../assets/Smart.png';
import UniBuzzy from '../../assets/UniBuzzy.png';
import Xray from '../../assets/Xray.png';

function Works() {
  const projects = [
    {
      title: 'AwareHome SmartBathroom Dashboard',
      description: 'This is a web-app that allows occupational therapists to analyze and interact with the data coming from sensors within the SmartBathroom to monitor patient movements and assess physical health of older adults.',
      imageSrc: Smart, 
      skills: [
        { name: 'IN PROGRESS', color: '#F16C7F' },
        { name: 'Full-stack Development', color: '#A6CBF5' },
        { name: 'Research', color: '#93D375' },
        { name: 'Design',  color: '#67C6C0'}
      ],
      projectLink: 'https://sites.gatech.edu/awarehome/smartbathroom/'
    },
    {
      title: 'UniBuzzy',
      description: 'As a university student, UniBuzzy is your go-to app to connect with other students on campus, attend exciting events, and discuss the hottest news.',
      imageSrc: UniBuzzy, 
      skills: [
        { name: 'IN PROGRESS', color: '#F16C7F' },
        { name: 'Full-stack Development', color: '#A6CBF5' },
        { name: 'Leadership', color: '#BE88C6' }
      ],
      projectLink: 'https://www.linkedin.com/company/unibuzzyofficial/mycompany/'
    },
    {
      title: 'COVID-19 Detection Model',
      description: 'This is an AI-driven project for COVID-19 diagnosis using X-ray analysis, achieving 90% accuracy through research and comparison of different models and optimization..',
      imageSrc: Xray, 
      skills: [
        { name: 'IN PROGRESS', color: '#F16C7F' },
        { name: 'Back-end Development', color: '#7C92FF' },
        { name: 'Research', color: '#93D375' }
      ],
      projectLink: 'https://vip.gatech.edu/teams/vwi'
    },
    {
      title: 'Punchshot Pickleball',
      description: 'Punchshot Pickleball is a client-requested project managed by GT WebDev that allows pickleball players to join and create teams and compete in local leagues.',
      imageSrc: Pickle, 
      skills: [
        { name: 'COMPLETE', color: '#61dafb' },
        { name: 'Front-end Development', color: '#D6F693' }
      ],
      projectLink: 'https://github.com/Nathan-Lin8/Punchshot-Pickleball'
    },
    {
      title: 'Consultative Sales Assessment',
      description: 'This is a generative AI-powered application under Technology Liberation Corporation that assesses sales performance of a company’s sales team.',
      imageSrc: CSA, 
      skills: [
        { name: 'Internship', color: '#61dafb' },
        { name: 'Front-end Development', color: '#D6F693' },
        { name: 'Design',  color: '#67C6C0'}
      ],
      projectLink: 'https://www.linkedin.com/company/technology-liberation-corporation/'
    },
  ];

  return (
    <div className="works">
      <h2 className="works-title">My Works</h2>
      <div className="polaroid-container">
        {projects.map((project, index) => (
          <ProjectPolaroid
            key={index}
            imageSrc={project.imageSrc}
            title={project.title}
            description={project.description}
            skills={project.skills}
            projectLink={project.projectLink}
          />
        ))}
      </div>
    </div>
  );
}

export default Works;
