import React, { useEffect } from 'react';
import Vara from 'vara';
import gsap from 'gsap';
import { MotionPathPlugin } from 'gsap/MotionPathPlugin';
import './Homepage.css';
import Markers from '../../assets/Markers.jpg';
import Tray from '../../assets/Tray.png'; // Assuming you have this image for the silver tray

const Homepage = () => {
    useEffect(() => {
        // Register the MotionPathPlugin with GSAP
        gsap.registerPlugin(MotionPathPlugin);

        let fontSize;

        // Match the CSS media queries to set the font size
        if (window.innerWidth <= 480) {
            fontSize = 48;
        } else if (window.innerWidth <= 768) {
            fontSize = 52;
        } else if (window.innerWidth <= 1024) {
            fontSize = 96;
        } else {
            fontSize = 96;
        }

        const varaContainer = document.getElementById('vara-container');
        const mainText = document.getElementById('main-text');
        const navbar = document.getElementById('navbar');
        const stickyNotes = document.querySelectorAll('.sticky-note'); // Get all sticky notes
        const airplane = document.querySelector('.paper-airplane-icon');
        const markersImage = document.querySelector('.markers-image');
        const trayImage = document.querySelector('.tray-image');
        const volleyball = document.querySelector('.volleyball-icon');
        const smiley = document.querySelector('.smiley-icon');

        if (varaContainer && mainText && navbar && airplane && markersImage && trayImage && volleyball && smiley) {
            varaContainer.innerHTML = ''; // Reset container content

            const vara = new Vara(
                "#vara-container",
                "https://raw.githubusercontent.com/akzhy/Vara/master/fonts/Shadows-Into-Light/shadows-into-light.json",
                [
                    {
                        text: "Hi There!",
                        y: 20, // Move text slightly down to avoid top clipping
                        duration: 2000
                    },
                ],
                {
                    strokeWidth: 3,
                    color: "#383839",
                    fontSize: fontSize,
                    textAlign: "center"
                }
            );

            vara.ready(function () {
                vara.animationEnd(function () {
                    // Create a GSAP timeline to synchronize animations
                    const timeline = gsap.timeline();

                    // Fade out the "Hi There!" container
                    timeline.to(varaContainer, {
                        opacity: 0,
                        duration: 0.5
                    });

                    // Fade in the main text, navbar, paper airplane, markers image, tray image, volleyball, and smiley together
                    timeline.to([mainText, navbar, airplane, markersImage, trayImage, volleyball, smiley], {
                        opacity: 1,
                        duration: 0.6,
                        ease: "power2.out",
                        onComplete: () => {
                            animatePaperAirplane(); // Trigger airplane loop after fade-in
                            animateVolleyball();    // Trigger volleyball drop after fade-in
                            animateSmiley();        // Trigger smiley face rotation after fade-in
                        }
                    });

                    // Add sticky note falling animation (one by one)
                    timeline.fromTo(
                        stickyNotes,
                        { opacity: 0, y: -150 }, // Start above the viewport and hidden
                        {
                            opacity: 1,
                            y: 0, // Bring them to their original position
                            stagger: 0.2, // Delay between each sticky note falling in
                            ease: "bounce.out", // Bounce effect for falling
                            duration: 0.5,
                        }
                    );
                });
            });

            return () => {
                varaContainer.innerHTML = ''; // Clear the vara container on unmount
            };
        }
    }, []);

    // Function to perform a loop animation with the paper airplane
    const animatePaperAirplane = () => {
        gsap.to('.paper-airplane-icon', {
            duration: 2, // Duration of the loop
            ease: 'power1.inOut',
            motionPath: {
                path: [
                    { x: 0, y: 0 },      // Start at center
                    { x: 30, y: -30 },   // Move up and right
                    { x: 60, y: -60 },   // Further up and right
                    { x: 30, y: -90 },   // Curve to up and left
                    { x: 0, y: -120 },   // Top of the loop
                    { x: -30, y: -90 },  // Curve down to the left
                    { x: -60, y: -60 },  // Continue down and left
                    { x: -30, y: -30 },  // Curve to down and right
                    { x: 0, y: 0 }       // Back to start
                ],
                align: '.paper-airplane-icon',
                alignOrigin: [0.5, 0.5],
                autoRotate: true, // Automatically rotate along the path
            },
            onComplete: () => {
                // Reset the position of the paper airplane for future animations
                gsap.set('.paper-airplane-icon', { x: 0, y: 0, rotation: 0 });
            }
        });
    };

    const animateVolleyball = () => {
        const tl = gsap.timeline({ repeat: 0 });
    
        tl.to('.volleyball-icon', {
            y: '35vh', // Drop near the bottom of the viewport
            duration: 0.8, // Time to drop
            ease: 'power2.in', // Smooth drop
        })
        .to('.volleyball-icon', {
            y: '15vh', // First bounce up
            duration: 0.6, // Bounce duration
            ease: 'power2.out', // Smooth bounce up
        })
        .to('.volleyball-icon', {
            y: '35vh', // Second drop, slightly lower
            duration: 0.4, // Shorter drop
            ease: 'power2.in', // Smooth drop
        })
        .to('.volleyball-icon', {
            y: '25vh', // First bounce up
            duration: 0.4, // Bounce duration
            ease: 'power2.out', // Smooth bounce up
        })
        .to('.volleyball-icon', {
            y: '33vh', // Second drop, slightly lower
            duration: 0.4, // Shorter drop
            ease: 'power2.in', // Smooth drop
        })
    };
    

    // Function to rotate the smiley face when clicked or on initial fade-in
    const animateSmiley = () => {
        gsap.to('.smiley-icon', {
            rotation: 360, // Rotate by 360 degrees
            duration: 1.5, // Time for the rotation
            ease: "power2.inOut", // Smooth animation
            onComplete: () => {
                gsap.set('.smiley-icon', { rotation: 0 }); // Reset rotation for replay
            }
        });
    };

    const handleFallAnimation = (event) => {
        event.preventDefault(); // Prevent default anchor click behavior
        const stickyNote = event.currentTarget;
        const targetSection = stickyNote.getAttribute('href'); // Get the target section (hash)
    
        // GSAP animation: make the sticky note fall off
        gsap.to(stickyNote, {
            rotation: 20, // Rotate a bit to give a falling effect
            y: 100, // Fall down by 100 pixels
            opacity: 0,
            duration: 0.3,
            ease: "power2.out", // Easing effect for the fall
            onComplete: () => {
                // Scroll to the respective section after the animation
                const section = document.querySelector(targetSection);
                if (section) {
                    section.scrollIntoView({ behavior: 'smooth' });
    
                    // Delay the update of the URL hash to match the scroll timing
                    setTimeout(() => {
                        window.history.pushState(null, null, targetSection); // Update the URL without reloading
                    }, 0); // Adjust this delay to match the scroll duration (set to 1 second here)
                }
    
                // Increased delay before the sticky note returns
                setTimeout(() => {
                    // Return animation after a longer delay
                    gsap.to(stickyNote, {
                        rotation: 0,
                        y: 0,
                        opacity: 1,
                        duration: 0.5,
                        ease: "power2.out"
                    });
                }, 1500); // Delay for sticky note return animation
            }
        });
    };
    

    return (
        <div className="homepage-container">
            {/* Paper Airplane Icon */}
            <div className="paper-airplane-icon" onClick={animatePaperAirplane}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <path fill="none" stroke="#383839" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                          d="m9.474 16l9.181 3.284a1.1 1.1 0 0 0 1.462-.887L21.99 4.239c.114-.862-.779-1.505-1.567-1.13L2.624 11.605c-.88.42-.814 1.69.106 2.017l2.44.868l1.33.467M13 17.26l-1.99 3.326c-.65.808-1.959.351-1.959-.683V17.24a2 2 0 0 1 .53-1.356l3.871-4.2"/>
                </svg>
            </div>

            {/* Volleyball Icon */}
            <div className="volleyball-icon" onClick={animateVolleyball}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
                    <path fill="#383839" d="M128 24a104 104 0 1 0 104 104A104.11 104.11 0 0 0 128 24m81.74 136.58a88 88 0 0 1-93.49 3.78L132.62 136h83a87.2 87.2 0 0 1-5.88 24.58M91.12 48.11a87.6 87.6 0 0 1 24.22-7.2a88 88 0 0 1 50 79.09h-32.72ZM215.63 120h-34.26a104.18 104.18 0 0 0-35.78-78.23A88.18 88.18 0 0 1 215.63 120M77.27 56.13l17.12 29.65a104.14 104.14 0 0 0-49.86 70.09a87.95 87.95 0 0 1 32.74-99.74M58.9 182.43a88 88 0 0 1 43.49-82.79L118.76 128l-41.49 71.87a88.6 88.6 0 0 1-18.37-17.44M128 216a87.5 87.5 0 0 1-36.88-8.11l17.13-29.67a104.23 104.23 0 0 0 85.53 8.17A87.8 87.8 0 0 1 128 216"/>
                </svg>
            </div>

            {/* Smiley Face Icon */}
            <div className="smiley-icon" onClick={animateSmiley}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                    <path fill="#383839" d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M1.5 8a6.5 6.5 0 1 0 13 0a6.5 6.5 0 0 0-13 0m3.82 1.636a.75.75 0 0 1 1.038.175l.007.009q.155.177.35.31c.264.178.683.37 1.285.37s1.02-.192 1.285-.371q.195-.132.35-.31l.007-.008a.75.75 0 0 1 1.222.87l-.022-.015c.02.013.021.015.021.015v.001l-.001.002l-.002.003l-.005.007l-.014.019a2 2 0 0 1-.184.213q-.241.25-.53.445c-.63.418-1.37.638-2.127.629c-.946 0-1.652-.308-2.126-.63a3.3 3.3 0 0 1-.715-.657l-.014-.02l-.005-.006l-.002-.003v-.002h-.001l.613-.432l-.614.43a.75.75 0 0 1 .183-1.044ZM12 7a1 1 0 1 1-2 0a1 1 0 0 1 2 0M5 8a1 1 0 1 1 0-2a1 1 0 0 1 0 2"/>
                </svg>
            </div>

            {/* Navbar */}
            <div id="navbar" className="navbar">
                <a href="#about" className="sticky-note" onClick={handleFallAnimation}>About</a>
                <a href="#skills" className="sticky-note" onClick={handleFallAnimation}>Skills</a>
                <a href="#works" className="sticky-note" onClick={handleFallAnimation}>Works</a>
                <a href="#contact" className="sticky-note" onClick={handleFallAnimation}>Contact</a>
            </div>

            {/* Animation Container */}
            <div id="vara-container"></div>
            <div id="main-text" className="text-container">
                <h1 className="title">I'm Nathan Lin</h1>
                <p className="subtitle">Developer & Designer</p>
            </div>
            
            {/* Markers Image */}
            <img src={Markers} alt="Markers" className="markers-image" />

            {/* Tray Image */}
            <img src={Tray} alt="Tray" className="tray-image" />
        </div>
    );
};

export default Homepage;
