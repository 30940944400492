import React, { useEffect, useState, useRef } from 'react';
import Homepage from './sections/HomePage/Homepage';
import About from './sections/About/About';
import Skills from './sections/Skills/Skills';
import Works from './sections/Works/Works';
import Contact from './sections/Contact/Contact';
import MenuStickyNote from './components/MenuStickyNote';

function App() {
  const [showMenu, setShowMenu] = useState(false);
  const [activeSection, setActiveSection] = useState('#homepage'); // Track the current active section

  const homepageRef = useRef(null);
  const aboutRef = useRef(null);
  const skillsRef = useRef(null);
  const worksRef = useRef(null);
  const contactRef = useRef(null);

  useEffect(() => {
    const sections = [
      { ref: homepageRef, id: 'homepage', threshold: 0.5 },
      { ref: aboutRef, id: 'about', threshold: 0.5 },
      { ref: skillsRef, id: 'skills', threshold: 0.5 },
      { ref: contactRef, id: 'contact', threshold: 0.5 },
    ];

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const sectionId = `#${entry.target.id}`;
            setActiveSection(sectionId); // Update active section
          }
        });
      },
      { threshold: sections.map((section) => section.threshold) }
    );

    // Observe all sections except "Works"
    sections.forEach((section) => {
      if (section.ref.current) {
        observer.observe(section.ref.current);
      }
    });

    // Create a separate observer for the "Works" section
    const worksObserver = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setActiveSection('#works'); // Set the active section to "Works"
          }
        });
      },
      { threshold: 0.2 } // Set a lower threshold specifically for "Works"
    );

    // Observe the "Works" section
    if (worksRef.current) {
      worksObserver.observe(worksRef.current);
    }

    return () => {
      // Unobserve all sections on cleanup
      sections.forEach((section) => {
        if (section.ref.current) {
          observer.unobserve(section.ref.current);
        }
      });
      if (worksRef.current) {
        worksObserver.unobserve(worksRef.current);
      }
    };
  }, []);

  useEffect(() => {
    const currentHomepageRef = homepageRef.current;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (!entry.isIntersecting) {
            setShowMenu(true);
          } else {
            setShowMenu(false);
          }
        });
      },
      { threshold: 0.5 }
    );

    if (currentHomepageRef) {
      observer.observe(currentHomepageRef);
    }

    return () => {
      if (currentHomepageRef) {
        observer.unobserve(currentHomepageRef);
      }
    };
  }, []);

  return (
    <div className="app-container">
      <div id="homepage" className="section snap-section" ref={homepageRef}>
        <Homepage />
      </div>
      <div id="about" className="section snap-section" ref={aboutRef}>
        <About />
      </div>
      <div id="skills" className="section snap-section" ref={skillsRef}>
        <Skills />
      </div>
      <div id="works" className="section" ref={worksRef}>
        <Works />
      </div>
      <div id="contact" className="section snap-section" ref={contactRef}>
        <Contact />
      </div>

      {/* Pass activeSection to MenuStickyNote */}
      {showMenu && <MenuStickyNote activeSection={activeSection} />}
    </div>
  );
}

export default App;
